.quizHistoryContainr {
    margin-top: 2%;
    margin-left: 1%;
    margin-right: 1%;
}

.accordianStyle {
    margin-left: 2%;
    margin-top: 2%;
    margin-right: 2%;
}

.accordianTitle {
    color: #36454F;
}