/* .assignmentCard{

} */

.assignmentTitle {
    /* margin-top: 4px;
    margin-left: 8px; */
    color: rgb(70, 70, 70);
    /* color: blue; */
}

.cardStyle {
    margin-top: 8px;
}

.viewButton {
    margin-top: 8px;
}

.assignmentBody {
    margin-top: 8px;
}

.instruction {
    margin-top: 8px;
    color: rgb(79, 77, 77);
    font-size: 18px;

}

.paperClipIcon{
    margin-top: -4px;
    margin-right: 0px;
    margin-right: 4px;
}