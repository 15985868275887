.container {
  display: flex;
  /* text-align: center; */
  justify-content: center;
  /* margin-left: 15%; */
  /* margin-right: 15%; */
}

.container h1{
  padding-bottom: 16px;
}

.title {
  font-size: 25px;
}

.inputContainer {
  margin-left: 15%;
  margin-right: 15%;
}

.formContainer {
  margin-bottom: 5%;
  margin-top: 5%;
}

.checkBoxSize {
  margin-left: 20px;
}

.wholePadder {
  margin-left: 15%;
}

.padder {
  /* align-items: center;
    /* text-align: center; */
  justify-content: center;
  display: flex;
}

.classPadder {
  margin-top: 8px;
}

.signUpButton {
  margin-top: 20px;
}
