.announcementContainer {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 2%;
}

.messageAuthor {
    color: rgb(78, 78, 78);
    font-size: 19px;
}

.timeStyle {
    color: rgb(169, 166, 166);
    /* font-weight: 500; */
}

.messageStyle {
    /* font-weight: 500; */
    color: rgb(124, 124, 124);
    padding-bottom: 0px;
}

.padderContainer{
    margin-top: 0px;
    margin-bottom: 0px;
}